import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import basmala from "@/assets/basmala.png";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.esm.min.js";
import "@popperjs/core";
import "./assets/style.css";

Vue.filter("basmala", function (value) {
  var Rval = "";
  Rval = value.replace(
    "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
    '<div class="basmala"><img src="' + basmala + '"></div>'
  );
  Rval = Rval.replace(
    "بِّسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
    '<div class="basmala"><img src="' + basmala + '"></div>'
  );
  Rval = Rval.replace(
    "۩",
    '<span class="tashkeel-orange" ref="prostration">۩</span>'
  ).replace(
    " ۗ",
    '<span class="tashkeel-orange" ref="prostration1">ۗ</span>'
  ).replace(
    " ۚ",
    '<span class="tashkeel-orange" ref="prostration2">ۚ </span>'
  ).replace(
    " ۖ",
    '<span class="tashkeel-orange" ref="prostration3">ۖ</span>'
  );
  return Rval;
});


Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
