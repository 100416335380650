import Vue from "vue";
import VueRouter from "vue-router";
import SurahAyah from '@/components/SurahAyah'
import Settings from '@/components/Settings'
import Page from '@/components/Page'
import Surah from '@/components/Surah'
import Juza from '@/components/Juza'
import Dictionary from "@/components/dictionary";

Vue.use(VueRouter);
const url = document.querySelector('meta[name=url]').getAttribute('content');
const routes = [
  {
    path: url,
    name: "Home",
    component: SurahAyah,
  },
  {
    path: url + 'settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: url + 'juza',
    name: 'Juza',
    component: Juza
  },
  {
    path: url + 'surah',
    name: 'Surah',
    component: Surah
  },
  {
    path: url + 'page',
    name: 'Page',
    component: Page
  },
  {
    path: url + 'dictionary',
    name: 'Dictionary',
    component: Dictionary
  },
  {
    path: url + ':id',
    name: 'SurahId',
    component: SurahAyah
  },
  {
    path: url + ':id/:ayah',
    name: 'SurahAyah',
    component: SurahAyah
  },
];

const router = new VueRouter({
  mode: "history",
  base: '',
  routes,
});

export default router;
