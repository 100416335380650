<!-- eslint-disable vue/html-closing-bracket-spacing -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="content">
          <div class="form-group">
            <label>اللغة</label>
            <div class="btn-wrapper">
              <button class="active">العربية</button>
              <button v-bind:hidden="true">English</button>
            </div>
          </div>
          <div class="divider"></div>
          <div class="form-group">
            <div class="switch-wrapper">
              <label class="switch">
                <input @change="setMode()" v-model="mode" type="checkbox" />
                <span class="slider round"></span>
              </label>
              <label>الوضع الليلي</label>
            </div>
          </div>
          <div class="divider"></div>
          <div class="form-group">
            <label>الخط</label>
            <select class="form-control" @change="setFont($event)" v-model="font">
              <option value="hafs">KFGQPC Hafs</option>
              <option value="naskh">KFGQPC Naskh</option>
              <option value="me_quran">Me-Quran</option>
              <option value="saleem">PDMS Saleem</option>
              <option value="scheherazade">Scheherazade</option>
            </select>
          </div>
          <div class="divider"></div>
          <div class="form-group">
            <label>الحجم</label>
            <select class="form-control"  @change="setSize($event)" v-model="size">
              <option value="17.2px">80%</option>
              <option value="19.35px">90%</option>
              <option value="21.5px">100%</option>
              <option value="23.6px">110%</option>
              <option value="25.8px">120%</option>
              <option value="28px">130%</option>
              <option value="30px">140%</option>
              <option value="32.25px">150%</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* -----------------------------general styles----------------------------- */
.content {
  padding: 2rem 0.25rem;
}
label {
  font-weight: 500;
  font-size: 16px;
  color: var(--text);
  margin-bottom: 0.5rem;
  display: block;
}

.divider {
  display: block;
  height: 0.5px;
  background-color: #99acc4;
  width: 100%;
  margin: 2rem 0;
}

/* -----------------------------buttons styles----------------------------- */
.btn-wrapper {
  display: flex;
  column-gap: 0.5rem;
}
.btn-wrapper button {
  flex-grow: 1;
  border: 1px solid #99acc4;
  background-color: transparent;
  border-radius: 4px;
  padding: 0.25rem;
  color: var(--text);
}
.btn-wrapper button.active {
  border: 1px solid var(--secondery);
  background-color: rgba(13, 155, 132, 0.1);
  font-weight: 4z00;
}

/* -----------------------------switch styles----------------------------- */
.switch-wrapper {
  display: flex;
  column-gap: 0.75rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--secondery);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--secondery);
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
/* -----------------------------Select styles-----------------------------*/
select {
  display: inline-block;
  margin: 0.15rem;
  padding: 0.5rem 0.25rem;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--secondery);
  background-color: var(--background);
  color: var(--text);
}
</style>

<script>
import Cookies from 'js-cookie';

export default {
  // eslint-disable-next-line vue/component-definition-name-casing, vue/multi-word-component-names
  name: 'Settings',
  data() {
    return {
      windowHeight: window.innerHeight,
      mode: Cookies.get('mode') == 'true' ? true : false,
      font: Cookies.get('font') || 'hafs',
      size: Cookies.get('size') || '21.5px'
    };
  },
  methods: {
    setMode() {
      this.$parent.changeMode(this.mode);
    },
    setFont(event) {
      this.$parent.changeFont(event.target.value);
    },
    setSize(event) {
      this.$parent.changeSize(event.target.value);
    },
  },
};
</script>
