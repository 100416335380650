<template>
  <div id="app">
    <div class="pb-80">
      <router-view />
    </div>
    <BottomNavbar />
  </div>
</template>

<style scoped>
  .pb-80{
    padding-bottom: 80px;
  }
</style>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import BottomNavbar from './components/layout/bottom-navbar.vue';

export default {
  name: 'App',
  components: {
    BottomNavbar,
  },
  data() {
    return {
      settings: {
        reader: '',
        language: 'ar',
        surah: [],
      },
    };
  },
  mounted() {
    this.run();
  },
  methods: {
    run() {
      let savedMode = Cookies.get('mode') == 'true' ? true : false;
      this.changeMode(savedMode)
      axios.get('https://quranapi.maqare.com/v1/surah').then((response) => {
        this.surah = response.data.data;
      });
      if (Cookies.get('reader')) {
        this.settings.reader = Cookies.get('reader');
      }
    },
    changeMode(newMode) {
      if (newMode) {
        document.querySelector('body').setAttribute('data-theme', 'dark');
        Cookies.set('mode', true, {expires: 360});
      } else {
        document.querySelector('body').setAttribute('data-theme', 'light');
        Cookies.set('mode', false, {expires: 360});
      }
    },
    changeFont(newFont) {
      Cookies.set('font', newFont, {expires: 360});
    },
    changeSize(newSize) {
      Cookies.set('size', newSize, {expires: 360});
    },
    changePage(newPage){
      Cookies.set('page', newPage, {expires: 360});
    }
  },
};
</script>
