<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/valid-attribute-name -->
<!-- eslint-disable vue/html-closing-bracket-spacing -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="container">
    <div class="row py-3">
      <div class="col-4" v-for="index in 30">
        <div class="juz" @click="redirectToFristJuzaPage(index)">الجزء {{ index }}</div>
      </div>
    </div>
  </div>
</template>

<style>
.juz {
  text-decoration: none;
  color: var(--text);
  font-family: 'Tajawal', sans-serif;
  background-color: var(--card-bg);
  border: 1px solid var(--card-border);
  display: inline-block;
  margin: 8px 0;
  border-radius: 8px;
  width: 100%;
  padding: 5px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;

}
</style>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';

export default {
  // eslint-disable-next-line vue/component-definition-name-casing, vue/multi-word-component-names
  name: 'Juza',
  data() {
    return {
      windowHeight: window.innerHeight,
      pages: [],
    };
  },
  computed: {},
  mounted() {},
  async created() {},
  methods: {
    getSave() {
      if (Cookies.get('page')) {
        this.save_get = true;
        this.save_page = Cookies.get('page');
        this.save_ayah = Cookies.get('ayah');
      }
    },
    convertToArabic(num) {
      const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
      return new String(num).replace(/[0123456789]/g, (d) => {
        return arabicNumbers[d];
      });
    },
    async getFristPageOfJuza(id) {
      const response = (await axios.get(`//api.alquran.cloud/v1/juz/${id}/quran-uthmani`)).data;

      return response.data.ayahs[0].page;
    },
    async redirectToFristJuzaPage(juzaNumber) {
      const pageNumber = await this.getFristPageOfJuza(juzaNumber);
      this.$router.push({name: 'SurahId', params: {id: pageNumber}});
    },
  },
};
</script>
