<!-- eslint-disable vue/html-closing-bracket-spacing -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="container">
    <div class="row px-3 pt-4 tabs-wrapper" :class="{'surah-border': currentTab == 'surah','juz-border': currentTab == 'juz','page-border': currentTab == 'page'}">
      <div class="col-4 text-center tab" :class="{active: currentTab == 'surah'}" @click="selectTab('surah')">السورة</div>
      <div class="col-4 text-center tab" :class="{active: currentTab == 'juz'}" @click="selectTab('juz')">الجزء</div>
      <div class="col-4 text-center tab" :class="{active: currentTab == 'page'}" @click="selectTab('page')">الصفحة</div>
    </div>
    <div class="row">
      <Surah v-if="currentTab == 'surah'" />
      <Juza v-if="currentTab == 'juz'" />
      <Page v-if="currentTab == 'page'" />
    </div>
  </div>
</template>

<style>
.tabs-wrapper {
  border-bottom: 0.25px solid var(--separator-color);
  position: sticky;
  top: 0;
  background-color: var(--background);
  z-index: 10;
}

.tabs-wrapper::after {
  content: "";
  position: absolute;
  width: 30%;
  height: 2px;
  bottom: -1px;
  background-color: var(--secondery);
  transition: right 200ms linear;
}

.tabs-wrapper.surah-border::after {
  right: 3%;
}

.tabs-wrapper.juz-border::after {
  right: 33%;
}

.tabs-wrapper.page-border::after {
  right: 67%;
}

.tab {
  padding-bottom: 10px;
  color: var(--text);
  font-size: 14px;
}

.tab.active {
  color: var(--secondery);
  font-weight: bold;
}
</style>

<script>
import Surah from './Surah.vue';
import Juza from './Juza.vue';
import Page from './Page.vue';

export default {
  // eslint-disable-next-line vue/component-definition-name-casing, vue/multi-word-component-names
  name: 'Dictionary',

  data() {
    return {
      currentTab: 'surah',
    };
  },
  components: {
    Surah,
    Juza,
    Page,
  },
  computed: {},
  mounted() {},
  methods: {
    selectTab(target) {
      this.currentTab = target;
    },
  },
};
</script>
