<!-- eslint-disable vue/html-closing-bracket-spacing -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="container">
    <div class="row py-3">
      <div class="col-4 number-wrapper" v-for="index in 604" :key="index" @click="redirectToPage(index)">
          <span>
            <div class="page" :class="{'page-right': index % 2, 'page-left': !(index % 2), twoChar: index > 9, threeChar: index > 99}">{{ index }}</div>
            <img v-if="index % 2" src="../assets/imgs/dictionary/right-page.png" :alt="'page number ' + index" />
            <img v-else src="../assets/imgs/dictionary/left-page.png" :alt="'page number ' + index" />
          </span>
      </div>
    </div>
  </div>
</template>

<style>
.number-wrapper {
  position: relative;
  text-align: center;
}

.number-wrapper img{
  width: 100%;
}
.page {
  position: absolute;
  top: 32%;
  font-size: 17px;
  color: #ffffff;
}

.page-right {
  right: 32%;
}

.page-right.twoChar {
  right: 28%;
}

.page-right.threeChar {
  right: 25%;
}

.page-left {
  left: 31%;
}

.page-left.twoChar {
  left: 28%;
}

.page-left.threeChar {
  left: 25%;
}
</style>

<script>
import Cookies from 'js-cookie';

export default {
  // eslint-disable-next-line vue/component-definition-name-casing, vue/multi-word-component-names
  name: 'Page',

  data() {
    return {
      windowHeight: window.innerHeight,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getSave() {
      if (Cookies.get('page')) {
        this.save_get = true;
        this.save_page = Cookies.get('page');
        this.save_ayah = Cookies.get('ayah');
      }
    },
    convertToArabic(num) {
      const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
      return new String(num).replace(/[0123456789]/g, (d) => {
        return arabicNumbers[d];
      });
    },
    redirectToPage(pageNumber){
      this.$router.push({name: 'SurahId', params: {id: pageNumber}});
    }
  },
};
</script>
