<!-- eslint-disable vue/html-closing-bracket-spacing -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="quran-view">
    <div class="header">
      <p class="surah-name">{{ surahName }}</p>
      <div class="pagination">
        <button :style="[this.currentPage > 1 ? {visibility: 'visible'} : {visibility: 'hidden'}]" @click="goLink(0)">
          <span class="material-symbols-outlined"> chevron_right </span>
          السابق
        </button>

        <p class="page-num">{{ this.currentPage }}</p>

        <button :style="[this.currentPage < 604 ? {visibility: 'visible'} : {visibility: 'hidden'}]" @click="goLink(1)">
          التالى
          <span class="material-symbols-outlined"> chevron_left </span>
        </button>
      </div>
      <p class="juz-num">الجزء {{ juz | englishToArabicNum }}</p>
    </div>

    <hooper :wheelControl="false" :centerMode="true" :infiniteScroll="true" :initialSlide="1" :perPage="1" :rtl="true" @slide="slide($event)">
      <!-- prevSlide  -->
      <slide>
        <!-- current page  -->
        <div v-if="!isLoad" class="container" :style="{fontFamily: font_family, fontSize: font_size}">
          <span v-for="(value, index) in firstSurah.ayahs" :key="index" :data-id="value.number" :id="'button' + value.number">
            <!-- surah title  -->
            <div v-if="value.numberInSurah == 1" class="surah-title">
              {{ firstSurah['surahsNames'][value.surahId] }}
            </div>

            <!-- Basmala  -->
            <span class="ayah" v-if="firstSurah.surahs[0].englishName == 'Al-Faatiha' && value.numberInSurah == 1" v-html="value.text" :data-id="index"></span>

            <!-- Ayat text -->
            <span class="ayah" v-else :inner-html.prop="value.text | basmala" :data-id="index"></span>

            <!-- Ayat number  -->
            <span class="ayaNumber" style="font-size: 0.91em; color: var(--secondery)">
              ﴿<span class="ayahnumber-number">{{ value.numberInSurah | englishToArabicNum }}</span
              >﴾
            </span>
          </span>
        </div>
        <div v-else class="loading"></div>
      </slide>

      <!-- currentSlide  -->
      <slide>
        <!-- current page  -->
        <div v-if="!isLoad" class="container" :style="{fontFamily: font_family, fontSize: font_size}">
          <span v-for="(value, index) in secondSurah.ayahs" :key="index" :data-id="value.number" :id="'button' + value.number">
            <!-- surah title  -->
            <div v-if="value.numberInSurah == 1" class="surah-title">
              {{ secondSurah['surahsNames'][value.surahId] }}
            </div>

            <!-- Basmala  -->
            <span class="ayah" v-if="secondSurah.surahs[0].englishName == 'Al-Faatiha' && value.numberInSurah == 1" v-html="value.text" :data-id="index"></span>

            <!-- Ayat text -->
            <span class="ayah" v-else :inner-html.prop="value.text | basmala" :data-id="index"></span>

            <!-- Ayat number  -->
            <span class="ayaNumber" style="font-size: 0.91em; color: var(--secondery)">
              ﴿<span class="ayahnumber-number">{{ value.numberInSurah | englishToArabicNum }}</span
              >﴾
            </span>
          </span>
        </div>
        <div v-else class="loading"></div>
      </slide>

      <!-- nextSlide  -->
      <slide>
        <!-- current page  -->
        <div v-if="!isLoad" class="container" :style="{fontFamily: font_family, fontSize: font_size}">
          <span v-for="(value, index) in thirdSurah.ayahs" :key="index" :data-id="value.number" :id="'button' + value.number">
            <!-- surah title  -->
            <div v-if="value.numberInSurah == 1" class="surah-title">
              {{ thirdSurah['surahsNames'][value.surahId] }}
            </div>

            <!-- Basmala  -->
            <span class="ayah" v-if="thirdSurah.surahs[0].englishName == 'Al-Faatiha' && value.numberInSurah == 1" v-html="value.text" :data-id="index"></span>

            <!-- Ayat text -->
            <span class="ayah" v-else :inner-html.prop="value.text | basmala" :data-id="index"></span>

            <!-- Ayat number  -->
            <span class="ayaNumber" style="font-size: 0.91em; color: var(--secondery)">
              ﴿<span class="ayahnumber-number">{{ value.numberInSurah | englishToArabicNum }}</span
              >﴾
            </span>
          </span>
        </div>
        <div v-else class="loading"></div>
      </slide>
    </hooper>
  </div>
</template>

<style>
.loading:empty {
  margin: auto;
  width: 95%;
  height: 85vh;

  background-image: 
    linear-gradient(100deg, rgba(255, 255, 255, 0), var(--skeleton-shadow) 50%, rgba(255, 255, 255, 0) 80%), 
    linear-gradient(var(--skeleton-color) 20px, transparent 0),
    linear-gradient(var(--skeleton-color) 20px, transparent 0), 
    linear-gradient(var(--skeleton-color) 20px, transparent 0), 
    linear-gradient(var(--skeleton-color) 20px, transparent 0);

  background-repeat: repeat-y;

  background-size: 
  50px 200px, 
  100% 200px, 
  100% 200px, 
  100% 200px, 
  100% 200px;

  background-position: 
  0 0, 
  0 0, 
  0 40px, 
  0 80px, 
  0 120px;

  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 
    100% 0, 
    0 0, 
    0 40px, 
    0 80px, 
    0 120px;
  }
}

.header {
  background: var(--bar-bg);
  position: sticky;
  top: 0%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  font-family: 'Tajawal', sans-serif;
  z-index: 10;
  margin-bottom: 0.5rem;
}

.header p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--bar-text);
}

.header .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2.25rem;
  width: 60%;
}

.header .pagination button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondery);
  font-size: 14px;
}

.header .pagination span {
  color: var(--secondery) !important;
  font-size: 1.5rem;
  line-height: 20px;
  width: 20px;
}

.header .surah-name {
  width: 25%;
  text-align: start;
}

.header .juz-num {
  width: 25%;
  text-align: end;
}

.header .pagination .page-num {
  font-size: 14px;
  color: var(--secondery);
}

.header button {
  border: none;
  background-color: transparent;
  width: 20px;
}

.header button img {
  width: 100%;
}

.basmala {
  width: 100%;
  text-align: center;
  margin-bottom: 0.5rem;
}
.basmala img {
  width: 60%;
  filter: var(--basmla-filter);
}

.tashkeel-orange {
  color: orange !important;
  font-size: 22px !important;
}

.surah-title {
  background-image: url('../assets/imgs/quran-view/title-frame.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 90%;
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  font-weight: 500;
  color: var(--text);
}

.quran-view {
  text-align: justify;
  font-family: hafs;
}

.quran-view span {
  margin-bottom: 0;
  line-height: 45px;
  text-align: justify;
  color: var(--text);
}

.ayahnumber-number {
  color: var(--secondery) !important;
  font-weight: 400;
  font-family: 'hafs', sans-serif !important;
  padding-inline: 2px;
}
</style>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import {Hooper, Slide} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'SurahAyah',
  filters: {
    englishToArabicNum: function (value) {
      let valueString = String(value);
      let id = '٠١٢٣٤٥٦٧٨٩'.split('');
      return valueString.replace(/[0-9]/g, function (w) {
        return id[w];
      });
    },
  },
  data() {
    return {
      npage: [],
      surah: [], // array الآيات
      prevSurah: [],
      nextSurah: [],
      firstSurah: [],
      secondSurah: [],
      thirdSurah: [],
      prevPage: 0,
      nextPage: 0,
      currentPage: Cookies.get('page'),
      mainSlide: 'second',
      par: Number,
      page: 0, // رقم الصفحة
      juz: 0,
      surahName: 0,
      ayah: 0, // رقم الآية
      set_save: false, // حفظ مكان التوقف
      isLoad: false, // Loading Page
      tRead: Number, // رقم الآية التي يتم قرائتها في الوقت الفعلي
      volume: 100, // مستوى الصوت يقسم على واحد
      fontSize: 16, // حجم الخط px,
      font_family: Cookies.get('font') || 'hafs',
      font_size: Cookies.get('size') || '21.5px !important',
      windowHeight: window.innerHeight,
      isInitialized: false,
    };
  },
  watch: {
    $route: 'run',
  },
  mounted() {
    this.run();
  },
  created() {
    this.run();
  },
  components: {
    Hooper,
    Slide,
  },
  methods: {
    async run() {
      await this.setLoad(true);
      this.isLoad = true
      this.npage = this.$parent.settings.npage;
      this.show = true;
      this.currentPage = this.par = this.$route.params.id ? this.$route.params.id : this.currentPage || 1;

      // this.$parent.changePage(this.currentPage);
      // history.pushState({}, null, `${this.currentPage}`);
      this.prevPage = Number(this.currentPage) > 1 ? Number(this.currentPage) - 1 : 604;
      this.nextPage = Number(this.currentPage) < 604 ? Number(this.currentPage) + 1 : 1;
      let Qar = this.$parent.settings.reader;
      this.ayah = this.$route.params.ayah;
      if (!this.set_save) {
        if (Cookies.get('page')) {
          this.set_save = true;
        }
      }
      //get current page
      await axios.get('https://quranapi.maqare.com/v1/page/' + this.currentPage + '' + Qar).then((response) => {
        this.surah = [];
        this.secondSurah = response.data;
        this.secondSurah['surahsNames'] = this.getSurahs(response.data.surahs);
        // console.log(this.secondSurah);
        this.page = response.data.ayahs[0].page;
        this.juz = response.data.ayahs[0].juz;
        // let surahNumber = response.data.data.ayahs[0].juz
        this.surahName = response.data.surahs[Object.keys(response.data.surahs)[0]].name.replace(/[ؐ-ًؕ-ٖٓ-ٟۖ-ٰٰۭ]/g, '');
        this.show = false;
        this.setLoad(false);
        this.setFontFamily();
      });
      await this.runthird(this.nextPage);
      await this.runfirst(this.prevPage);
      this.scrollView(0);
      this.isLoad = false
    },
    async runfirst(page) {
      this.isLoad = true
      let Qar = this.$parent.settings.reader;
      if (page > 0) {
        await axios.get('https://quranapi.maqare.com/v1/page/' + page + '' + Qar).then((response) => {
          this.firstSurah = [];
          this.firstSurah = response.data;
          this.firstSurah['surahsNames'] = this.getSurahs(response.data.surahs);
        });
      }
      this.isLoad = false
    },
    async runSecond(page) {
      this.isLoad = true
      let Qar = this.$parent.settings.reader;
      if (page < 605) {
        await axios.get('https://quranapi.maqare.com/v1/page/' + page + '' + Qar).then((response) => {
          this.secondSurah = [];
          this.secondSurah = response.data;
          this.secondSurah['surahsNames'] = this.getSurahs(response.data.surahs);
        });
      }
      this.isLoad = false
    },
    async runthird(page) {
      this.isLoad = true
      let Qar = this.$parent.settings.reader;
      if (page < 605) {
        await axios.get('https://quranapi.maqare.com/v1/page/' + page + '' + Qar).then((response) => {
          this.thirdSurah = [];
          this.thirdSurah = response.data;
          this.thirdSurah['surahsNames'] = this.getSurahs(response.data.surahs);
        });
      }
      this.isLoad = false
    },
    async slide(payload) {
      if (this.isInitialized) {
        if (payload.currentSlide < payload.slideFrom) {
          this.currentPage = this.currentPage == 1 ? 604 : Number(this.currentPage) - 1;
          this.prevPage = this.prevPage == 1 ? 604 : this.prevPage - 1;
          this.nextPage = this.nextPage == 1 ? 604 : this.nextPage - 1;
          this.$parent.changePage(this.currentPage);
          //go prev slide
          switch (this.mainSlide) {
            case 'first':
              this.runSecond(this.prevPage);
              this.mainSlide = 'third';
              break;
            case 'second':
              this.runthird(this.prevPage);
              this.mainSlide = 'first';
              break;
            case 'third':
              this.runfirst(this.prevPage);
              this.mainSlide = 'second';
              break;
          }
        } else if (payload.currentSlide > payload.slideFrom) {
          this.currentPage = this.currentPage == 604 ? 1 : Number(this.currentPage) + 1;
          this.prevPage = this.prevPage == 604 ? 1 : this.prevPage + 1;
          this.nextPage = this.nextPage == 604 ? 1 : this.nextPage + 1;
          this.$parent.changePage(this.currentPage);
          //go next slide
          switch (this.mainSlide) {
            case 'first':
              this.runthird(this.nextPage);
              this.mainSlide = 'second';
              break;
            case 'second':
              this.runfirst(this.nextPage);
              this.mainSlide = 'third';
              break;
            case 'third':
              this.runSecond(this.nextPage);
              this.mainSlide = 'first';
              break;
          }
        }
        let Qar = this.$parent.settings.reader;
        await axios.get('https://quranapi.maqare.com/v1/page/' + this.currentPage + '' + Qar).then((response) => {
          this.juz = response.data.ayahs[0].juz;
          this.surahName = response.data.surahs[Object.keys(response.data.surahs)[0]].name.replace(/[ؐ-ًؕ-ٖٓ-ٟۖ-ٰٰۭ]/g, '');
        });

        history.pushState({}, null, `${this.currentPage}`);
      }
      setTimeout(() => {
        // window.scrollTo({top: 0, behavior: 'smooth'});
        this.scrollView(0);
      }, 300);
      this.isInitialized = true;
    },
    goLink(val = null) {
      this.setLoad(true);
      this.$router.push({
        name: 'SurahId',
        params: {id: val ? Number(this.currentPage) + 1 : Number(this.currentPage) - 1},
      });
    },
    goPage() {
      this.setLoad(true);
      this.$router.push({name: 'SurahId', params: {id: this.par}});
    },

    page_number(val = null) {
      const ppage = this.page;
      if (val == 'add') {
        this.$router.push({name: 'SurahId', params: {id: ppage + 1}});
      } else if (val == 'sub') {
        this.$router.push({name: 'SurahId', params: {id: ppage - 1}});
      } else {
        this.$router.push({name: 'SurahId', params: {id: ppage}});
      }
    },
    style(val) {
      let style = document.querySelector('style');
      if (val == 'add') this.fontSize++;
      else if (val == 'sub') this.fontSize--;
      style.innerHTML = 'span strong {font-size:' + this.fontSize + 'px;}';
    },
    setFontFamily() {
      let style = document.querySelector('style');
      if (this.font_family !== 'def') {
        style.innerHTML = 'span strong {font-family:' + this.font_family + ';}';
      } else {
        style.innerHTML = '';
      }
    },
    save() {
      var page = this.page;
      if (!ayah) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'قم بأختيار الآية ليتم حفظ مكان التوقف',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      var ayah = document.querySelector('.x-start').getAttribute('data-id');
      Cookies.set('page', page, {expires: 360});
      Cookies.set('ayah', ayah, {expires: 360});
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'تم حفظ مكان التوقف',
        showConfirmButton: false,
        timer: 1500,
      });
    },
    open_save() {
      if (Cookies.get('page')) {
        this.setLoad(true);
        var page = Cookies.get('page');
        var ayah = Cookies.get('ayah');
        this.$router.push({
          name: 'SurahId',
          params: {id: page, ayah: ayah},
        });
      }
    },

    setLoad(val) {
      if (val && document.querySelector('.ayah.load')) document.querySelector('.ayah.load').setAttribute('aria-load', 'true');
      else if (document.querySelector('.ayah.load')) document.querySelector('.ayah.load').setAttribute('aria-load', 'false');
    },
    scrollView(amount) {
      let scrollFromTop = window.pageYOffset;
      const viewHeight = Math.round(window.innerHeight * Math.abs(amount));
      if (scrollFromTop % viewHeight === 0) scrollFromTop += Math.sign(amount);
      let targetPos;
      if (amount > 0) targetPos = Math.ceil(scrollFromTop / viewHeight) * viewHeight;
      else targetPos = Math.floor(scrollFromTop / viewHeight) * viewHeight;
      window.scrollTo({
        top: targetPos,
        behavior: 'smooth',
      });
    },
    getSurahs(surahs){
      let surahObj = {}
      surahs.map((surah)=>{
        surahObj[surah.number] = surah.name
      })
      return surahObj
    }
  },
};
</script>
