<!-- eslint-disable vue/html-closing-bracket-spacing -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="bottom-navbar">
    <button class="homepage" type="button">
      <router-link exact-active-class="active" :to="{name:'SurahId'}">
        <img src="../../assets/imgs/navbar/homepage.png" alt="Menu Homepage" />
      </router-link>
    </button>

    <button class="menu" type="button">
      <router-link exact-active-class="active" to="/dictionary">
        <img src="../../assets/imgs/navbar/menu.png" alt="Menu Menu" />
      </router-link>
    </button>

    <button class="setting" type="button">
      <router-link exact-active-class="active" to="/settings">
        <img src="../../assets/imgs/navbar/settings.png" alt="Menu Setting" />
      </router-link>
    </button>
  </div>
</template>

<style scoped>
.bottom-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--navbar-bg);
  box-shadow: var(--navbar-shadow);
  display: flex;
  justify-content: space-around;
  padding: 15px 0 35px;
  margin-bottom: -5px;
}
button {
  border: none;
  background-color: transparent;
}
button .active img {
  filter: var(--secondery-filter);
}
button.homepage img {
  width: 25px;
  height: 17px;
}
button.menu img {
  width: 21px;
  height: 24px;
}
button.setting img {
  width: 23px;
  height: 24px;
}
button img {
  width: 100%;
}
</style>

<script>
export default {
  // eslint-disable-next-line vue/component-definition-name-casing, vue/multi-word-component-names
  name: 'BottomNavbar',
};
</script>
