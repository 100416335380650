<!-- eslint-disable vue/html-closing-bracket-spacing -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="container">
    <div class="row py-3">
      <div v-for="(value, index) in filteredList" :key="index" class="col-4">
          <router-link
            class="surah"
            :to="{
              name: 'SurahId',
              params: {id: nPage[value.number - 1]},
            }"
          >
            <div>
              {{ value.number }}.
              {{ value.name.split(' ').slice(1).join(' ').replace(/[ؐ-ًٖؕ-ٟۖ-ۭ]/g,'') }}
            </div>
          </router-link>
      </div>
    </div>
  </div>
</template>

<style>
.surah{
  text-decoration: none;
  color: var(--text);
  font-family: 'Tajawal', sans-serif;
  background-color: var(--card-bg);
  border: 1px solid var(--card-border);
  display: inline-block;
  width: 100%;
  margin: 8px 0;
  border-radius: 8px;
  padding: 5px 0;
  text-align: center;
  font-weight: 500;
}
</style>

<script>
import Cookies from 'js-cookie';
import jsonSurah from '@/assets/Surah';

export default {
  // eslint-disable-next-line vue/component-definition-name-casing, vue/multi-word-component-names
  name: 'Surah',

  data() {
    return {
      surah: jsonSurah.data,
      save_get: false,
      save_page: 0,
      save_ayah: 0,
      keyword: '',
      nPage: [
        '1',
        '2',
        '50',
        '77',
        '106',
        '128',
        '151',
        '177',
        '187',
        '208',
        '221',
        '235',
        '249',
        '255',
        '262',
        '267',
        '282',
        '293',
        '305',
        '312',
        '322',
        '332',
        '342',
        '350',
        '359',
        '367',
        '377',
        '385',
        '396',
        '404',
        '411',
        '415',
        '418',
        '428',
        '434',
        '440',
        '446',
        '453',
        '458',
        '467',
        '477',
        '483',
        '489',
        '496',
        '499',
        '502',
        '507',
        '511',
        '515',
        '518',
        '520',
        '523',
        '526',
        '528',
        '531',
        '534',
        '537',
        '542',
        '545',
        '549',
        '551',
        '553',
        '554',
        '556',
        '558',
        '560',
        '562',
        '564',
        '566',
        '568',
        '570',
        '572',
        '574',
        '575',
        '577',
        '578',
        '580',
        '582',
        '583',
        '585',
        '586',
        '587',
        '587',
        '589',
        '590',
        '591',
        '591',
        '592',
        '593',
        '594',
        '595',
        '595',
        '596',
        '596',
        '597',
        '597',
        '598',
        '598',
        '599',
        '599',
        '600',
        '600',
        '601',
        '601',
        '601',
        '602',
        '602',
        '602',
        '603',
        '603',
        '603',
        '604',
        '604',
        '604',
      ],
      windowHeight: window.innerHeight,
    };
  },
  computed: {
    filteredList() {
      return this.surah.filter((post) => {
        return (
          post.name.replace(new RegExp(String.fromCharCode(1617, 124, 1614, 124, 1611, 124, 1615, 124, 1612, 124, 1616, 124, 1613, 124, 1618), 'g'), '') +
          ' - ' +
          post.number
        )
          .toLowerCase()
          .includes(this.keyword.toLowerCase());
      });
    },
  },
  mounted() {},
  methods: {
    getSave() {
      if (Cookies.get('page')) {
        this.save_get = true;
        this.save_page = Cookies.get('page');
        this.save_ayah = Cookies.get('ayah');
      }
    },
    convertToArabic(num) {
      const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
      return new String(num).replace(/[0123456789]/g, (d) => {
        return arabicNumbers[d];
      });
    },
  },
};
</script>
